import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.server.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { UserService } from './views/shared/user.service';
import { CommonService } from './views/shared/common.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { LayoutModule } from './layout/layout.module';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthIntercepter } from './auth/auth.intercepter';
import { AccordionModule } from 'primeng/accordion';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QRCodeModule } from 'angular2-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


const config: SocketIoConfig = {
  url: 'http://localhost:3000/',  
  options: {
    transports: ['websocket']
  }
}

////Stage instance

//const GoogleClientId = '251046259681-743509qtfppspi2le00t8ufsnk3bqpkt.apps.googleusercontent.com';

////Live instance
const GoogleClientId = '';
const FacebookAppID = '';

@NgModule({
  declarations: [
    AppComponent
    //NavMenuComponent,
    //HomeComponent,
    //CounterComponent,
    //FetchDataComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule, HttpClientModule, ReactiveFormsModule, LayoutModule,
    //ToastrModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      tapToDismiss: false
    }),
    BrowserAnimationsModule, RouterModule, FormsModule,
    PerfectScrollbarModule,
    NgSelectModule, AccordionModule, NgxDatatableModule, QRCodeModule,ZXingScannerModule
      
    , SocketIoModule.forRoot(config),
      SocialLoginModule
  ],
  providers: [UserService,
    CommonService,{
      provide: [HTTP_INTERCEPTORS, PERFECT_SCROLLBAR_CONFIG],
      useClass: AuthIntercepter,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,

      multi: true,

    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              GoogleClientId,
              {
                scope: 'profile email',
                plugin_name: 'login' 
              }
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(FacebookAppID)
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
