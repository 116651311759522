import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService, ModuleList } from '../views/shared/common.service';
import { CommonTokenExpireService } from '../views/shared/confirmation-tokenexpire/common-token-expire.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  modulePermission: ModuleList;
  constructor(
    private modalService: CommonTokenExpireService,
    private router: Router, private commonService: CommonService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem("access_token") != null) {

      let moduleCode = next.data.moduleCode;
      
      if (moduleCode != '' && typeof (moduleCode) != 'undefined' && moduleCode != null) {
        this.modulePermission = this.commonService.getPermission(moduleCode);
        if (typeof this.modulePermission === 'undefined' || this.modulePermission === null) {
          let data = localStorage.getItem('moduleList');
          this.router.navigate(['/dashboard']);
          return false;
        }
        if (this.modulePermission.roleModuleReadFlag == false) {
          this.router.navigate(['/dashboard']);
          return false;

        }
      }
      let arraydata = [];
      let data = localStorage.getItem('moduleList');
     
      arraydata.push(JSON.parse(data));
      
      if (next.data.moduleCode != '' && next.data.moduleCode != null) {
        if (next.data.moduleCode == '5274' || next.data.moduleCode == '4026' || next.data.moduleCode == '5245' ||
          next.data.moduleCode =='5238') {

        }
        else {
          var value = arraydata[0].filter(item => item.moduleCode === next.data.moduleCode);
          
          
          if (value != null && typeof value != 'undefined' && value != '') {
          }
          
          else {
            this.router.navigate(['/dashboard']);
            return false;
          }
        }
      }
     
      return true;
    }
    else {
      this.router.navigate(['/account']);
      return false;
    }
  }
}
