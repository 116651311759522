import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServerModule } from '@angular/platform-server';
import { ModuleMapLoaderModule } from '@nguniversal/module-map-ngfactory-loader';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/account',
    pathMatch: 'full'
  },
  { path: 'dashboard', loadChildren: './views/dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard], data: { moduleCode: '' } }
  ,
  {
    path: 'user', loadChildren: './views/manageuser/user.module#UserModule', canActivate: [AuthGuard], data: { moduleCode: '2002' },
    pathMatch: "prefix"
  },
  {
    path: 'myprofile', loadChildren: './views/userprofile/userprofile.module#UserModule', canActivate: [AuthGuard]

  }
  ,
  {
    path: 'emailsettings', loadChildren: './views/emailsettings/emailsettings.module#EmailsettingsModule', canActivate: [AuthGuard], data: {
      moduleCode: '5211'
    }
  },
  {
    path: 'account',
    loadChildren: './views/account/account.module#AccountModule',
    data: { preload: true, showHeader: false, showSidebar: false },
    pathMatch: "prefix"
  },
  {
    path: 'role',
    loadChildren: './views/role/role.module#RoleModule',
    canActivate: [AuthGuard], data: { moduleCode: '2003' }
  }
 
  , {
    path: 'emailtemplates',
    loadChildren: './views/emailtemplate/emailtemplate.module#EmailtemplateModule',
    canActivate: [AuthGuard], data: { moduleCode: '4021' }
  },

  {
    path: 'master',
    loadChildren: './views/master/master.module#MasterModule'
  },
 
  
  {
    path: 'mastername',
    loadChildren: './views/mastername/mastername.module#MasternameModule'
  },
  
  {
    path: 'subscriptions',//4022
    loadChildren: './views/subscriptionmaster/subscriptionmaster.module#SubscriptionmasterModule', canActivate: [AuthGuard], data: { moduleCode: '4022' }
  },
  {
    path: 'company',
    loadChildren: './views/managecompany/managecompany.module#ManagecompanyModule', canActivate: [AuthGuard], data: {
      moduleCode: '4023'
    },
  },
  
 
  {
    path: 'location',  //5229
    loadChildren: './views/managelocationangular/managelocation.module#ManageLocationModule', canActivate: [AuthGuard], data: { moduleCode: '5229' }
  },
  

  {
    path: 'productcategories',
    loadChildren: './views/productcategories/productcategories.module#ProductCategoriesModule', canActivate: [AuthGuard], data: { moduleCode: '9003' }
  },
  {
    path: 'authoriseSig',
    loadChildren: './views/restrictmedicines/restrictmedicine.module#RestrictmedicineModule', canActivate: [AuthGuard], data: { moduleCode: '5238' }
  },
  {
    path: 'products',
    loadChildren: './views/products/products.module#ProductsModule', canActivate: [AuthGuard], data: { moduleCode: '9001' }
  }
  ,
  {
    path: 'managetests',
    loadChildren: './views/managetests/managetests.module#ManagetestsModule',
    canActivate: [AuthGuard], data: { moduleCode: '4026' }
  },
  {
    path: 'managevideo',
    loadChildren: './views/managevideo/managevideo.module#ManagevideoModule', canActivate: [AuthGuard], data: { moduleCode: '4025' }
  }
  ,
  
  {
    path: 'subcontractor',
    loadChildren: './views/managesubcontractor/managesubcontractor.module#managesubcontractorModule', canActivate: [AuthGuard], data: { moduleCode: '4024' }
  }
  , {
    path: 'vaccination',
    loadChildren: './views/managevaccination/vaccination.module#VaccinationModule', canActivate: [AuthGuard], data: { moduleCode: '5274' }
  }
  ,
  {
    path: 'manageQuestionnaire',
    loadChildren: './views/manageQuestionnaire/managequestionnaire.module#ManageQuestionnaireModule'
  },
  {
    path: 'observersetting',
    loadChildren: './views/settingobserver/settingobserver.module#ObserversettingModule', canActivate: [AuthGuard], data: { moduleCode: '4030' }
  },
  {
    path: 'dynamicpages',
    loadChildren: './views/managedynamicpages/dynamicpages.module#DynamicpagesModule', canActivate: [AuthGuard], data: { moduleCode: '8002' }
  },
  {
    path: 'testimonial',
    loadChildren: './views/managetestimonial/testimonial.module#TestimonialModule', canActivate: [AuthGuard], data: { moduleCode: '8008' }
  },
  {
    path: 'blogscategory',
    loadChildren: './views/manageblogscategory/blogscategory.module#BlogscategoryModule', canActivate: [AuthGuard], data: { moduleCode: '8003' }
  },
  {
    path: 'blogs',
    loadChildren: './views/manageblogs/blogs.module#BlogsModule', canActivate: [AuthGuard], data: { moduleCode: '8004' }
  },
  {
    path: 'news',
    loadChildren: './views/news/news.module#NewsModule', canActivate: [AuthGuard], data: { moduleCode: '8005' }
  },
  {
    path: 'testimonialcategory',
    loadChildren: './views/testimonialcategory/testimonialcategory.module#TestimonialcategoryModule', canActivate: [AuthGuard], data: { moduleCode: '8006' }
  },
  {
    path: 'managecustomer',
    loadChildren: './views/managecustommer/managecustommer.module#ManagecustomerModule', canActivate: [AuthGuard], data: { moduleCode: '809' }
  },
  {
    path: 'contactus',
    loadChildren: './views/contactus/contactusquery.module#ContactusqueryModule', canActivate: [AuthGuard], data: { moduleCode: '5211' }
  },
  {
    path: 'salesreport',
    loadChildren: './views/salesreport/salesreport.module#SalesReportModule'
  },
  {
    path: 'driver',
    loadChildren: './views/managemanagers/managers.module#ManagersModule',  data: { moduleCode: '5247' }
  },
  {
    path: 'termandcondition',
    loadChildren: './views/termandcondition/termandcondition.module#TermandconditionModule'
  },
  {
    path: 'manageservice',
    loadChildren: './views/manageservice/service.module#ServiceModule', canActivate: [AuthGuard], data: { moduleCode: '8007' }
  },
  {
    path: 'employeeinformation',
    loadChildren: './views/QRcodeemployeeinfo/employeeinfo.module#EmployeeinfoModule'
  },
  {
    path: 'companysubscription',
    loadChildren: './views/companysubscription/companysubscription.module#CompanysubscriptionModule'
  },
  
  {
    path: 'questionnaire',
    loadChildren: './views/EmployeeQuestionnare/employeequestionnaire.module#EmployeeQuestionnaireModule'
  },
  {
    path: 'managedqf',
    loadChildren: './views/manageguest/manageguest.module#ManageguestModule'
  },
  {
    path: 'guesttest',
    loadChildren: './views/guestmytest/guesttest.module#GuesttestModule'
  },
  
  {
    path: 'observertimeslot',
    loadChildren: './views/observertimeslotListing/manageobservertimeslot.module#ManageobservertimeslotModule'
  },
  {
    path: 'meetingsrequest',
    loadChildren: './views/managemettingreq/managemettingreq.module#managemettingreqModule'
  },
  
  {
    path: 'settingemployee',
    loadChildren: './views/EmployeeSetting/employeesetting.module#EmployeeSettingModule'
  },
  
  {
    path: 'qrcodescan',
    loadChildren: './views/qrcodescan/qrcodescan.module#QrcodescanModule'
  },
  {
    path: 'healthhistory',
    loadChildren: './views/managehealthhistory/healthhistory.module#HealthhistoryModule'
  },
  {
    path: 'testobservernow',
    loadChildren: './views/managetestobservenow/testobservernow.module#TestobservernowModule'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
