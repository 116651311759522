import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { Router, NavigationEnd } from '@angular/router';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public baseUri = `${environment.WebApiBaseUrl}`;


  public commonUri = `${environment.WebApiBaseUrl}Common`;
  public getLoggedInName = new BehaviorSubject<UserDetails>(new UserDetails());
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  Genders: any;
  masters: Master[] = [];
  states: SelectItemModel[] = [];
  Templates: SelectTemplateItemModel[] = [];
  downLoadModel: DownloadModel = new DownloadModel();
  businessName: SelectBusinessContactModel[] = [];
  isUploadImageValid = true;
  isUploadFileValid = true;
  LoginUser: UserDetails = new UserDetails();
  activeclass: boolean;
  private IsSideMenuCollapsed = new BehaviorSubject(false);
  IsMenuCollapsed = this.IsSideMenuCollapsed.asObservable();

  constructor(private http: HttpClient, private toastr: ToastrService, private routerService: Router, private toaster: ToastrService
    , private dialogService: ConfirmationDialogService
  ) {

    this.currentUrl = this.routerService.url;
    routerService.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  private progressSubject = new BehaviorSubject<number>(0);
  progress$ = this.progressSubject.asObservable();

  increaseProgress(): void {
    this.http.get(this.baseUri + `userProfile/GetProgressData?id=${localStorage.getItem("userId")}`).subscribe((response: any) => {
      if (response != null) {
        this.progressSubject.next(response);
      }

    });
   
  }

  SideMenuStatus(status: boolean) {
    this.IsSideMenuCollapsed.next(status)
  }

  download(url: string): Observable<Blob>{
    
    let file ={
      fileurl:url
    }
    return this.http.post(this.baseUri +`common/download/`, file,{
      responseType: 'blob'
    })
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
  isFileValid = true;

  validateAllFormFields(formGroup: FormGroup, iscroll = true) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
        return;
      }
      else if (control instanceof FormArray) {
        control.controls.forEach(arrControl => {
          if (arrControl instanceof FormGroup) {
            this.validateAllFormFields(arrControl);
            return;
          }
        })
        if (iscroll) {
          this.scroll(formGroup);
        }
      }

    });

  }
   
    downloadAllFiles(id: string): Observable<Blob> {
        return this.http.get(this.baseUri + `userProfile/GetFiles?driverId=${id}`, {
            responseType: 'blob',
        });
    }

  removeConnectionbyUserID(id: string) {
    return this.http.get(this.baseUri + `Account/removeConnection?userId=${id}`);
  }

  sendFileToCompanyAdmin(driverId: string, driverName: string, adminEmail: string) {
    return this.http.get(this.baseUri + `userProfile/SendFileToCompanyAdmin?driverId=${driverId}&driverName=${driverName}&adminEmail=${adminEmail}`);

  }

  getHeaderData(id: string, userIdentity: string) {
    userIdentity = localStorage.getItem('companyId');
    let actionType = "00Test";
    if (localStorage.getItem("actionType") == 'AsAObserver') {
      actionType = localStorage.getItem("actionType");
    }
    return this.http.get(this.baseUri + `Common/getHeaderData?id=${id}&userIdentity=${userIdentity}&actionType=${actionType}`);

  }
  DeletePrfoleImg(id) {
    return this.http.get(this.baseUri + `userProfile/DeletePrfoleImg?id=${id}`);
  }
  DeleteSignatureImg(id){
  return this.http.get(this.baseUri + `userProfile/DeleteSignatureImg?id=${id}`);
}
  GetDashboardCounts(userId,companyId) {
    return this.http.get(this.baseUri + `Common/GetDashboardCounts/${userId}/${companyId}`)
  }
  getGenders(masterNames: any, masterKeyText: any, uid: any): Observable<any> {
    return this.http.get(this.baseUri + `Customer/GetMasterItems/${masterNames}/${masterKeyText}/${uid}`).pipe(
      map((response: any) => {
        this.Genders = response;
      })
    );
  }
  GetRegitraionLink(id, email) {
    return this.http.get(this.baseUri + `UserProfile/GetRegitraionLink?id=${id}&email=${email}`);
  }
  getMasterItemsList(masterNames: any): Observable<any> {
    
    return this.http.get(this.baseUri + `Common/GetMasterItems/${masterNames}`).pipe(
      map((response: any) => {
        this.masters = response;
        

      })
    );
  }

  getMasterListForOnServices(masterNames: any, masterKeyText: any, userId: any): Observable<any> {
    return this.http.get(this.baseUri + `Role/GetMasterItems?masterNames=${masterNames}&masterKeyText=${masterKeyText}&userId=${userId}`).pipe(
      map((response: any) => {
        this.masters = response;
        

      })
    );
  }

  getMasterForUserServices(masterNames: any, masterKeyText: any, userId: any): Observable<any> {
    return this.http.get(this.baseUri + `Role/GetMasterItems?masterNames=${masterNames}&masterKeyText=${masterKeyText}&userId=${userId}`).pipe(
      map((response: any) => {
        this.masters = response;
      })
    );
  }
  
  getStateList() {
    return this.http.get(this.baseUri + `Bank/GetStateList`);
  }
  getSusbcriptionList() {
    return this.http.get(this.baseUri + `Bank/GetSubscriptionList`);
  }
  getGlobalSeacrchData(listFilter: string, length: number, recordFilter: string, teamID: string, teamMemberID: string,) {
    return this.http.get(this.baseUri + `Common/GetGlobalSeacrchData?listFilter=${listFilter}&length=${length}&recordFilter=${recordFilter}&teamID=${teamID}&teamMemberID=${teamMemberID}`);
  }

  getBusinessContactList(query: any): Observable<any> {
    return this.http.get(this.baseUri + `Contact/GetSearchResult?searchType=${query}`).pipe(
      map((response: any) => {
        this.businessName = response;

      })
    );
  }

  getMasterItemsInProgress(masterNames: any, masterKeyText: string): Observable<any> {
    return this.http.get(this.baseUri + `Common/GetMasterItems/masterNames=${masterNames}&masterKeyText=${masterKeyText}`).pipe(
      map((response: any) => {
        this.masters = response;

      })
    );
  }

  getStatesList(): Observable<any> {
    return this.http.get(this.baseUri + `Common/GetStatesList`).pipe(
      map((response: any) => {
        this.states = response;
      })
    );
  }

  getTemplateList(): Observable<any> {
    return this.http.get(this.baseUri + `Common/GetTemplateList`).pipe(
      map((response: any) => {
        this.Templates = response;
      })
    );
  }


  getBankDropList() {
    return this.http.get(this.baseUri + `Bank/GetBankDropList`);
  }
  gettoken(email: any) {
    return this.http.get(this.baseUri+`UserProfile/gettoken?email=${email}`)
  }
  setPassword(setPassword: SetPassword) {
    return this.http.post(this.baseUri + `Account/SetPassword`, setPassword);
  }
  ResetPassword(setPassword: SetPassword) {
    return this.http.post(this.baseUri + `Account/ResetPassword`, setPassword);
  }


  getCurrentUserDetail(userId, actionType = '') {
    if (actionType == '' || actionType == null || typeof actionType == undefined) {
      actionType = '00Test';
    }
    if (localStorage.getItem("actionType") == 'AsAObserver') {
      actionType = localStorage.getItem("actionType");
    }
    let optionaParamersType = "00Test";
    return this.http.get(this.baseUri + `UserProfile/GetUserInfo?userId=${userId}`).subscribe((response: any) => {
      if (response != null) {
        debugger;
        this.LoginUser = response;

        localStorage.removeItem('userinfo');

        localStorage.setItem('userinfo', JSON.stringify(response));
        localStorage.removeItem('companyId');
        localStorage.setItem("companyId", response.companyId);
        localStorage.setItem("companyName", response.companyName);
        localStorage.removeItem('SettingObserver');
        localStorage.setItem("SettingObserver", response.observerSettingName);
        localStorage.removeItem('SettingEmployee');
        localStorage.setItem("SettingEmployee", response.employeeSettingName);

        localStorage.setItem('sliderMenu', JSON.stringify(1));
        localStorage.setItem("usersucess", "yes");
        localStorage.removeItem('TimeZone');
        var TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; //response.timeZone;
        localStorage.setItem("TimeZone", TimeZone);
        this.getLoggedInName.next(this.LoginUser);
      }
      else {

        localStorage.setItem("usersucess", "fail");
        localStorage.removeItem("access_token");
        localStorage.removeItem("moduleList");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("userId");
        localStorage.removeItem("companyId");
        localStorage.removeItem('SettingObserver');
        localStorage.setItem("usersucess", "fail");
        this.LoginUser = new UserDetails();
      }

    },
      (error: any) => {
        localStorage.setItem("usersucess", "fail");
        localStorage.removeItem("access_token");
        localStorage.removeItem("moduleList");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("userId");
        localStorage.removeItem("companyId");
        localStorage.removeItem('SettingObserver');
        localStorage.setItem("usersucess", "fail");
        this.LoginUser = new UserDetails();
      })

  }
  getCurrentUserDetailWithModules(userId, actionType = '',isProfile='') {
    if (actionType == '' || actionType == null || typeof actionType == undefined) {
      actionType = '00Test';
    }
    if (localStorage.getItem("actionType") == 'AsAObserver') {
      actionType = localStorage.getItem("actionType");
    }
    
    let optionaParamersType = "00Test";
    return this.http.get(this.baseUri + `UserProfile/GetUserInfoWithModules?userId=${userId}&actionType=${actionType}`).subscribe((response: any) => {
      if (response != null) {
        this.LoginUser = response.userInfo;
       
        localStorage.removeItem('userinfo');

        localStorage.setItem('userinfo', JSON.stringify(response.userInfo));
        localStorage.removeItem('companyId');
        localStorage.setItem("companyId", response.userInfo.companyId);
        localStorage.setItem("isMandatoryFieldDone", response.userInfo.isMandatoryFieldDone);
        localStorage.setItem("signatureFileName", response.userInfo.signatureFileName);
        localStorage.removeItem('SettingObserver');
        localStorage.setItem("SettingObserver", response.userInfo.observerSettingName);
        localStorage.removeItem('SettingEmployee');
        localStorage.setItem("SettingEmployee", response.userInfo.employeeSettingName);

        localStorage.setItem('sliderMenu', JSON.stringify(1));
        localStorage.setItem("usersucess", "yes");
        localStorage.removeItem('TimeZone');
        var TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; //response.timeZone;
        localStorage.setItem("TimeZone", TimeZone);
        this.getLoggedInName.next(this.LoginUser);

        sessionStorage.removeItem("companyUrl");
        localStorage.removeItem('moduleList');
        localStorage.setItem('moduleList', JSON.stringify(response.roleModules));
        localStorage.setItem("usersucess", "yes");
        document.getElementById('main_full').setAttribute("class", "main")
        if (isProfile == '') {
          this.routerService.navigateByUrl('/dashboard');
        }
        else if (isProfile == '1') {
          this.routerService.navigate(['/myprofile'])
          location.reload();
      
        }
        
      }
      else {
      
        localStorage.setItem("usersucess","fail");
        localStorage.removeItem("access_token");
        localStorage.removeItem("moduleList");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("userId");
        localStorage.removeItem("companyId");
        localStorage.removeItem('SettingObserver');
        localStorage.setItem("usersucess", "fail");
        this.LoginUser = new UserDetails();
      }
      
    },
      (error: any) => { 
        localStorage.setItem("usersucess", "fail");
        localStorage.removeItem("access_token");
        localStorage.removeItem("moduleList");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("userId");
        localStorage.removeItem("companyId");
        localStorage.removeItem('SettingObserver');
        localStorage.setItem("usersucess", "fail");
        this.LoginUser = new UserDetails();
      })
    
  }

  getUserModulePermissions(userId, actionType) {
    if (actionType == '' || actionType == null || typeof actionType == undefined) {
      actionType = '00Test';
    }
    if (localStorage.getItem("actionType") == 'AsAObserver') {
      actionType = localStorage.getItem("actionType");
    }
    return this.http.get(this.baseUri + `common/GetUserModulePermissionList?userId=${userId}&actionType=${actionType}`);
  }
  GetProgressData(id: string) {
    return this.http.get(this.baseUri + `UserProfile/GetProgressData?id=${id}`);
  }

  getPermission(moduleCode: number) {
    const moduledata = JSON.parse(localStorage.getItem('moduleList'));

    if (moduledata !== null && typeof moduledata !== 'undefined') {
      let module1 = moduledata.find(m => m.moduleCode == moduleCode);
      if (module1 == undefined)
        module1 = "null";

      return module1;
    }
    else {
      return null;
    }
  }

  uploadDocumentSize(type: string, filesize: number, filelength: string) {
    
    if (filelength == "30MB") {

      if (type.toLowerCase() !== '') {
        if (filesize >= 31457280) {    // 30MB Size in Bytes.
          this.toastr.error("Please upload a file of max. size 30MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }

    }
    else if (filelength == "10MB") {
     
      if (type.toLowerCase() !== '') {
        if (filesize >= 10485760) { //10MB Size in Bytes.
          this.toastr.error("Please upload a file of max. size 10MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }
    }
    else {
      if (type.toLowerCase() !== '') {
        if (filesize >= 5242880) {//5MB Size in Bytes.
          this.toastr.error("Please upload a file of max. size 5MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }
    }
  }
  uploadCommunicationAttachmentSize(type: string, filesize: number, filelength: string) {
      if (type.toLowerCase() !== '') {
        if (filesize >= 10485760) {    // File Size is greater than 5MB (5242880 in Bytes).
          this.toastr.error("Please upload a file of max. size 10MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }
  }
  uploadDocumentSizeLeaseManagement(type: string, filesize: number) {
    if (type.toLowerCase() !== '') {
      if (filesize > 31457280) { //30MB Size in Bytes.
        this.toastr.error("Please upload a file of max. size 30MB.");
        this.isFileValid = false;
      }
      else {
        this.isFileValid = true;
      }
    }
  }
  uploadImageFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".png", ".jpeg", ".jpg", ".gif"];
    let blnValid = false; this.isUploadImageValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) { blnValid = true; break; }
    }
    if (!blnValid) {
      $file.target.value = ""; this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    }
    else {
      this.isUploadImageValid = true;
    }
  }
  uploadFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".png", ".jpeg", ".jpg", ".gif", ".pdf", ".msword", ".doc"];
    let blnValid = false; this.isUploadImageValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) { blnValid = true; break; }
    }
    if (!blnValid) {
      $file.target.value = ""; this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    }
    else {
      this.isUploadImageValid = true;
    }
  }
  TrainingMPAndPdfAndDocFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".doc", ".docx", ".pdf",".mp4", ".avi", ".mp3"];
    let blnValid = false; this.isUploadImageValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) { blnValid = true; break; }
    }
    if (!blnValid) {
      $file.target.value = ""; this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    }
    else {
      this.isUploadImageValid = true;
    }
  }
  uploadMPAndPdfAndDocFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".mp4", ".avi", ".mp3"];
    let blnValid = false; this.isUploadImageValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) { blnValid = true; break; }
    }
    if (!blnValid) {
      $file.target.value = ""; this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    }
    else {
      this.isUploadImageValid = true;
    }
  }
  uploadDocumentFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".doc", ".docx", ".pdf", ".xlsx", ".xls", ".png", ".jpeg", ".jpg", ".gif"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  //Test File validator only accept ".doc", ".docx", ".pdf", ".png", ".jpeg", ".jpg",
  uploadTestFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".doc", ".docx", ".pdf", ".png", ".jpeg", ".jpg"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  uploadVideoValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".mp4", ".avi"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }


  uploadPDFFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".pdf"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  uploadBulkFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".xlsx", ".xls"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  uploadBulkFileSize(type: string, filesize: number) {
    if (type.toLowerCase() !== '') {
      if (filesize > 26214400) {//25MB              5242880) {//5MB Size in Bytes.
        this.toastr.error("Please upload a file of max. size 25MB.");
        this.isFileValid = false;
      }
      else {
        this.isFileValid = true;
      }
    }
  }


  scrollTo(el: Element) {
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  scroll(form: FormGroup) {
    this.scrollIfFormHasErrors(form).then(() => {
    });
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }
  Delete(tableName: any, primaryid: any, moduleName: any) {
    return this.http.get(this.baseUri + `Common/DeleteRecord?tableName=${tableName}&primaryKey=${primaryid}&moduleName=${moduleName}`)
  }
  ActiveInactive(tableName: any, primaryid: any, status: any, moduleName: any, statusName: any) {
    return this.http.get(this.baseUri + `Common/UpdateActiveInactiveStatus?tableName=${tableName}&primaryKey=${primaryid}&status=${status}&moduleName=${moduleName}&statusName=${statusName}`)
  }
  ChangePassword(changePassword: ChangePasswordModel) {
    return this.http.post(this.baseUri + `Account/ChangePassword`, changePassword);
  }
  logout() {


    const message = `Are you sure you want to "sign out"?`;
    this.dialogService.confirm('Sign out', message).subscribe(confirmed => {
      if (confirmed) {
        document.getElementById('sidebar').setAttribute("class", "sidebar js-sidebar collapsed");
        document.getElementById('main_full').setAttribute("class", "main full-section-cc");
        setTimeout(() => { 
          let user = localStorage.getItem('userinfo');
          let companyName = localStorage.getItem('companyUrl');
          let redirectRoute = JSON.parse(user).userType == 'usertype09' ? '/account/company/' + companyName : '/account'
          localStorage.removeItem("access_token");
          localStorage.removeItem("moduleList");
          localStorage.removeItem("userinfo");
          localStorage.removeItem("userId");
          localStorage.removeItem("switcheduserId");
          localStorage.removeItem("SwichedAsSuperAdmin");
          localStorage.removeItem('isSwitched');
          localStorage.removeItem("loginSuperadminAsComp");
          localStorage.setItem("SwichedAsSuperAdmin",'no');
          localStorage.removeItem("companyId");
          localStorage.removeItem('SettingObserver');
          localStorage.removeItem('SettingEmployee');
          localStorage.setItem("usersucess", "fail");
          localStorage.removeItem('TimeZone');
          localStorage.removeItem('LocalTime');
          localStorage.removeItem("actualUserId");
          localStorage.removeItem("companyUrl");
          localStorage.removeItem('actionType');
          this.LoginUser = new UserDetails();
          this.routerService.navigateByUrl(redirectRoute);
          this.toaster.success(`You are signed out successfully`);
        }, 500);
      }
      else {

        document.getElementById('sidebar').setAttribute("class", "sidebar js-sidebar ");
        document.getElementById('main_full').setAttribute("class", "main");

      }
    });


  }
  //To Export data into .pdf, .xlsx and csv
  ExportData(records: any, type: any, filename: any, pageLength: any = "") {

    if (type == 'Excel') {
      var blob = new Blob([records], { type: 'text/plain;charset=windows-1252' });
      this.http.post(this.commonUri + `/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).subscribe(blob => {
        saveAs(blob, filename + ".xlsx")
      })
    }
    else if (type == 'CSV') {
      var blob = new Blob([records], { type: 'text/csv' });
      this.http.post(this.commonUri + `/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).subscribe(blob => {
        saveAs(blob, filename + ".csv")

      })
    }
    else {

      var blob = new Blob([new Uint8Array(records)], { type: 'application/pdf' });
      this.http.post(this.commonUri + `/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).subscribe(blob => {
        saveAs(blob, filename + ".pdf")
      })
    }
  }

  DownloadDocument(filename: any, folderName: any) {
    let fileType = this.DownLoadFiles(filename);
    this.downLoadModel.documentName = filename;
    this.downLoadModel.folderName = folderName;
    var blob = new Blob([], { type: fileType });
    this.http.post(`${this.commonUri}/DownLoadDocument`, this.downLoadModel, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, filename)
    });
  }
  DownLoadFiles(filename) {
    let fileName = filename;
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == ".txt") {
      fileType = "text/plain";
    }
    else if (checkFileType == ".pdf") {
      fileType = "application/pdf";
    }
    else if (checkFileType == ".doc") {
      fileType = "application/vnd.ms-word";
    }
    else if (checkFileType == ".docx") {
      fileType = "application/vnd.ms-word";
    }
    else if (checkFileType == ".xls") {
      fileType = "application/vnd.ms-excel";
    }
    else if (checkFileType == ".png") {
      fileType = "image/png";
    }
    else if (checkFileType == ".jpg") {
      fileType = "image/jpeg";
    }
    else if (checkFileType == ".jpeg") {
      fileType = "image/jpeg";
    }
    else if (checkFileType == ".gif") {
      fileType = "image/gif";
    }
    else if (checkFileType == ".csv") {
      fileType = "text/csv";
    }

    return fileType;
  }

  GetCurrency() {
    return "$";
  }

  getLicenseFee() {
    return 30;
  }


  getModuleList(userID: string) {
    let actionType = "00Test";
    if (localStorage.getItem("actionType") == 'AsAObserver') {
      actionType = localStorage.getItem("actionType");
    }
    return this.http.get(this.baseUri + `Common/GetModuleList?=${userID}&actionType=${actionType}`);
  }

  ExportSalesChart(records: any, filename: any, totalSale: string, totalOrder: string,filter:string) {
    var blob = new Blob([records], { type: 'text/plain;charset=windows-1252' });
    this.http.post(this.commonUri + `/ExportExcelChart/${filename}/${totalSale}/${totalOrder}/${filter}`, records, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, filename + ".xlsx")
    })
  }

  /*For video call file validate*/
  uploadFileforvideoValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".doc", ".docx", ".pdf", ".xlsx", ".xls", ".png", ".jpeg", ".jpg", ".gif", ".mp4", ".avi"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  //for masking in cardnumber field
  creditCardNumberSpacing(cardNumber) {
    if (cardNumber != null && cardNumber != undefined && cardNumber != '') {
      cardNumber = cardNumber.replace(/\s/g, '');
      let cardNumberArray = cardNumber.split('');
      let updatedCardNumber: string = '';
      cardNumberArray.forEach((item, index) => {
        if (!isNaN(item)) {
          updatedCardNumber = updatedCardNumber.concat(item);

          if ((index + 1) % 4 == 0 && index + 1 != cardNumberArray.length) {
            updatedCardNumber = updatedCardNumber.concat(' ');
          }
        }
      })
      return updatedCardNumber;
    }
  }
  //GetPageNoAfterDelete(pageInfo, pageNumber) {
  //  try { 
  //        let result = pageInfo.totalItems % pageInfo.pageSize
  //        if (result != null && result == 1) {
  //        return (pageNumber == 0) ? pageNumber : pageNumber - 1;
  //        }
  //        return '0';
  //    } catch (err) {
  //       return '0';
  //    }
  //  }
}

export class UserDetails {
  id: string;
  email: string;
  firstName: string = '';
  lastName: string = '';
  userType: string;
  fullName = this.firstName + " " + this.lastName;
}
export class Master {
  masterId: string;
  masterNameId: string;
  masterName: string;
  masterNames: string;
  masterKey: string;
  masterValue: string;
  masterStatusId: string;
  masterStatusName: string;
  masterIsDeleted?: boolean;
  enabled?: boolean;
  userId?: any;
  text: string;
  value: string;
  isActive: string;
  masterDescription: string;
  constructor() {
    this.masterId = '';
    this.masterNameId = null;
    this.masterName = '';
    this.masterNames = '';
    this.masterKey = '';
    this.masterValue = '';
    this.masterStatusId = null;
    this.masterStatusName = '';
    this.enabled = true;
    this.isActive = '';
    this.masterIsDeleted = false;
    this.masterDescription = '';
  }
}

export class SelectItemModel {
  value: string;
  text: string;
  constructor() {
    this.value = "";
    this.text = "";
  }
}
export class SelectBusinessContactModel {
  value: string;
  text: string;
  constructor() {
    this.value = "";
    this.text = "";
  }
}

export class SelectTemplateItemModel {
  value: string;
  text: string;
  constructor() {
    this.value = "";
    this.text = "";
  }
}
export class DocumentTypes {
  text: string;
  value: string;
}
export enum ModuleNames {
  Vendor = 0,
  Bank = 1,
  promoCode = 2,
  manageOrders = 3,

}
export class ChangePasswordModel {
  currentPassword: string;
  password: string;
  confirmPassword: string;
  userId: string;
  constructor() {
    this.currentPassword = "";
    this.password = "";
    this.confirmPassword = "";
    this.userId = "";
  }
}

export class DownloadModel {
  documentID: any;
  documentName: string;
  folderName: string;
  constructor() {
    this.documentID = null;
    this.documentName = '';
    this.folderName = '';
  }
}


export class ModuleList {
  moduleId: string;
  moduleCode: number;
  moduleName: string;
  moduleRoute: string;
  moduleUserType: string;
  roleName: string;
  roleID: string;
  roleDescription: string;
  roleModuleAddFlag: boolean;
  roleModuleUpdateFlag: boolean;
  roleModuleReadFlag: boolean;
  roleModuleDeleteFlag: boolean;
  roleModuleEmailFlag: boolean;
  roleModuleSMSFlag: boolean;
  roleModuleNotificationFlag: boolean;
}

export class ModuleModel {
  moduleId: any;
  moduleCode: number;
  moduleLinkCode: number;
  moduleName: string;
  moduleRoute: string;
  moduleCssClass: string;
  moduleParentModuleId: any;
  moduleCreatedBy: any;
  moduleCreatedOn: any;
  moduleDisplayOrder: string;
  constructor() {
    this.moduleId = null;
    this.moduleCode = 0;
    this.moduleLinkCode = 0;
    this.moduleName = "";
    this.moduleRoute = "";
    this.moduleCssClass = "";
    this.moduleParentModuleId = null;
    this.moduleCreatedBy = null;
    this.moduleCreatedOn = new Date();
    this.moduleDisplayOrder = "";
  }
}
export class SetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  token: any;
  ResetToken: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
    this.token = "";
    this.ResetToken = "";
  }
}
