import { PipeTransform, Pipe } from "@angular/core";
import * as moment from 'moment'



@Pipe({
  name: 'truncate'
})
export class TruncateWord implements PipeTransform {
  transform(value: string, limit = 15, completeWords = false, ellipsis = '...') {
    if (value) {
      if (value.length > limit) {
        if (completeWords) {
          limit = value.substr(0, limit).lastIndexOf(' ');
        }
        return `${value.substr(0, limit)}${ellipsis}`;
      }
      else {
        return `${value}`;
      }
    }
  }
}


@Pipe({
  name: 'utcdatetimetolocal'
})
export class UtcDateTimeToLocalPipe implements PipeTransform {
  rightNow: any; jan1: any; june1: any; temp: any; jan2: any; june2: any;
  transform(date: any): any {
    
    var newDateObj = moment(date).add(4, 'm').toDate();
    return this.GetLocalTimeFromUTC(date);
  }

  
  setTimeZone() {
   
    //if (localStorage.getItem("LocalTime") == null) {
    //  let date = new Date();
    //  this.jan1 = new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);  // jan 1st
    //  var june1 = new Date(date.getFullYear(), 6, 1, 0, 0, 0, 0); // june 1st
    //  this.temp = this.jan1.toUTCString();
    //  var jan2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
    //  this.temp = june1.toUTCString();
    //  var june2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
    //  var std_time_offset = (this.jan1 - this.jan2) / (1000 * 60 * 60);

    //  localStorage.setItem('LocalTime', this.convert(std_time_offset));
      

    //}
    
  }
 calculate_time_zone() {
   var minutes;
  
  localStorage["TimeZone"] != null;
   this.rightNow = new Date();
   this.jan1 = new Date(this.rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);  // jan 1st
   this.june1 = new Date(this.rightNow.getFullYear(), 6, 1, 0, 0, 0, 0); // june 1st
    this.temp = this.jan1.toUTCString();
  this.jan2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
   this.temp = this.june1.toUTCString();
  var june2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
  var std_time_offset = (this.jan1 - this.jan2) / (1000 * 60 * 60);
  var daylight_time_offset = (this.june1 - this.june2) / (1000 * 60 * 60);
  var dst;
  if (std_time_offset == daylight_time_offset) {
    dst = "0"; // daylight savings time is NOT observed
  } else {
    // positive is southern, negative is northern hemisphere
    var hemisphere = daylight_time_offset - std_time_offset;
    if (hemisphere >= 0) {
      std_time_offset = daylight_time_offset;
      dst = "1"; // daylight savings time is observed
    }
  }
  var i;
  // Here set the value of hidden field to the ClientTimeZone.
  return minutes = localStorage["LocalTime"] = this.convert(std_time_offset);

}
  dtMonth: any; sDay: any; sYear: any; sHour: any; sMinute: any; sAMPM: any;
formatDate(dateVal) {
  var newDate = new Date(dateVal);
 
  //var sMonth = padValue(newDate.getMonth() + 1);
  this.dtMonth = this.padValue(newDate.getMonth() + 1);
  this. sDay = this.padValue(newDate.getDate());
  this.sYear = newDate.getFullYear();
  this.sHour = newDate.getHours();
  this.sMinute = this.padValue(newDate.getMinutes());

  if (isNaN(this.dtMonth))
    return;
  this.sAMPM = "AM";

  var iHourCheck = parseInt(this.sHour);

  if (iHourCheck > 12) {
    this.sAMPM = "PM";
    this.sHour = iHourCheck - 12;
  }
  else if (iHourCheck === 0) {
    this.sHour = "12";
  }

  this.sHour = this.padValue(this.sHour);

  //var monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //var selectedMonthName = monthsArray.indexOf[sMonth];
  switch (this.dtMonth) {
    case '01':
      this.dtMonth = '01';
      break;
    case '02':
      this.dtMonth = '02';
      break;
    case '03':
      this.dtMonth = '03';
      break;
    case '04':
      this.dtMonth = '04';
      break;
    case '05':
      this.dtMonth = '05';
      break;
    case '06':
      this.dtMonth = '06';
      break;
    case '07':
      this.dtMonth = '07';
      break;
    case '08':
      this.dtMonth = '08';
      break;
    case '09':
      this.dtMonth = '09';
      break;
    case '10':
      this.dtMonth = '10';
      break;
    case '11':
      this.dtMonth = '11';
      break;
    case '12':
      this.dtMonth = '12';
      break;
  }
  return this.dtMonth + "-" + this.sDay + "-" + this.sYear + " " + this.sHour + ":" + this.sMinute + " " + this.sAMPM;
}

padValue(value) {
  return (value < 10) ? "0" + value : value;
}
// This function is to convert the timezoneoffset to Standard format
  convert(value) {
 
  var hours = parseInt(value);
  value -= parseInt(value);
  value *= 60;
  var mins = value;
  value -= parseInt(value);
  value *= 60;
  var secs = parseInt(value);
  var display_hours = hours.toString();
  // handle GMT case (00:00)
  if (hours == 0) {
    display_hours = "00";
  } else if (hours > 0) {
    // add a plus sign and perhaps an extra 0
    display_hours = ((hours < 10) ? "+0" + hours : "+" + hours).toString();
  } else {
    // add an extra 0 if needed
    display_hours = ((hours > -10) ? "-0" + Math.abs(hours) : hours).toString();
  }
  mins = (mins < 10) ? "0" + mins : mins;
  return display_hours + ":" + mins;
}
formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
  }
  timeOffSet: any; hourOffSet: any; minuteOffSet: any;
  minutesOffSet: any; preValOffSet: any; hoursOffSet: any; final: any;
convertOffset(gmt_offset) {
  this.timeOffSet = gmt_offset.toString().split(":");
  this.hourOffSet = parseInt(this.timeOffSet[0]);
  this.minuteOffSet = parseInt(this.timeOffSet[1]);
  this.minutesOffSet = this.minuteOffSet;
  this.preValOffSet = this.hourOffSet < 0 ? "-" : "+";
  this.hoursOffSet = Math.abs(this.hourOffSet) < 10 ? "0" + Math.abs(this.hourOffSet) : Math.abs(this.hourOffSet);
  //hour = negative ? "-" + hour : "+" + hour;
  this.final = parseInt(this.hoursOffSet);
  let data = this.final * 60;
  
  return this.preValOffSet + parseInt(data + this.minutesOffSet);
}
GetLocalTimeFromUTC(date) {
  
  var dateStringWithTime = moment(date).format('DD-MM-YYYY HH:mm:ss');
  var dateArr = dateStringWithTime.split('-');
  var val = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
  var dt_ = new Date(val);
  if (localStorage["LocalTime"] == null) {
    this.calculate_time_zone();
  }
 
  var TimeZoneHours = this.convertOffset(localStorage["LocalTime"]);
  let MILLISECS_PER_HOUR = TimeZoneHours /* min/hour */ * 60 /* sec/min */ * 1000 /* ms/s */;
  var newDate_ = new Date(+dt_ + MILLISECS_PER_HOUR);
  return this.formatDate(newDate_);
  }

}
@Pipe({
  name: 'utctolocal'
})
export class UtctolocalPipe implements PipeTransform {

  transform(date: any, toUTC: any): any {
    if (date == null) {
      return null;
    }
    date = new Date(date);
    var localOffset = date.getTimezoneOffset() * 60000;
    var localTime = date.getTime();
    if (toUTC) {
      date = localTime + localOffset;
    }
    else {
      date = localTime - localOffset;
    }
    date = new Date(date);

    return moment(date).format('MM-DD-YYYY')
  }

}

@Pipe({
  name: 'utcdatetimetolocalTimeOnly'
})
export class UtctolocalTimePipe implements PipeTransform {
  rightNow: any; jan1: any; june1: any; temp: any; jan2: any; june2: any;
  transform(date: any): any {

    return this.GetLocalTimeFromUTC(date)
  }


  setTimeZone() {

    //if (localStorage.getItem("LocalTime") == null) {
    //  let date = new Date();
    //  this.jan1 = new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);  // jan 1st
    //  var june1 = new Date(date.getFullYear(), 6, 1, 0, 0, 0, 0); // june 1st
    //  this.temp = this.jan1.toUTCString();
    //  var jan2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
    //  this.temp = june1.toUTCString();
    //  var june2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
    //  var std_time_offset = (this.jan1 - this.jan2) / (1000 * 60 * 60);

    //  localStorage.setItem('LocalTime', this.convert(std_time_offset));


    //}

  }
  calculate_time_zone() {
    var minutes;

    localStorage["TimeZone"] != null;
    this.rightNow = new Date();
    this.jan1 = new Date(this.rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);  // jan 1st
    this.june1 = new Date(this.rightNow.getFullYear(), 6, 1, 0, 0, 0, 0); // june 1st
    this.temp = this.jan1.toUTCString();
    this.jan2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
    this.temp = this.june1.toUTCString();
    var june2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(" ") - 1));
    var std_time_offset = (this.jan1 - this.jan2) / (1000 * 60 * 60);
    var daylight_time_offset = (this.june1 - this.june2) / (1000 * 60 * 60);
    var dst;
    if (std_time_offset == daylight_time_offset) {
      dst = "0"; // daylight savings time is NOT observed
    } else {
      // positive is southern, negative is northern hemisphere
      var hemisphere = daylight_time_offset - std_time_offset;
      if (hemisphere >= 0) {
        std_time_offset = daylight_time_offset;
        dst = "1"; // daylight savings time is observed
      }
    }
    var i;
    // Here set the value of hidden field to the ClientTimeZone.
    return minutes = localStorage["LocalTime"] = this.convert(std_time_offset);

  }
  dtMonth: any; sDay: any; sYear: any; sHour: any; sMinute: any; sAMPM: any;
  formatDate(dateVal) {
    var newDate = new Date(dateVal);

    //var sMonth = padValue(newDate.getMonth() + 1);
    this.dtMonth = this.padValue(newDate.getMonth() + 1);
    this.sDay = this.padValue(newDate.getDate());
    this.sYear = newDate.getFullYear();
    this.sHour = newDate.getHours();
    this.sMinute = this.padValue(newDate.getMinutes());

    if (isNaN(this.dtMonth))
      return;
    this.sAMPM = "AM";

    var iHourCheck = parseInt(this.sHour);

    if (iHourCheck > 12) {
      this.sAMPM = "PM";
      this.sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
      this.sHour = "12";
    }

    this.sHour = this.padValue(this.sHour);

    //var monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //var selectedMonthName = monthsArray.indexOf[sMonth];
    switch (this.dtMonth) {
      case '01':
        this.dtMonth = '01';
        break;
      case '02':
        this.dtMonth = '02';
        break;
      case '03':
        this.dtMonth = '03';
        break;
      case '04':
        this.dtMonth = '04';
        break;
      case '05':
        this.dtMonth = '05';
        break;
      case '06':
        this.dtMonth = '06';
        break;
      case '07':
        this.dtMonth = '07';
        break;
      case '08':
        this.dtMonth = '08';
        break;
      case '09':
        this.dtMonth = '09';
        break;
      case '10':
        this.dtMonth = '10';
        break;
      case '11':
        this.dtMonth = '11';
        break;
      case '12':
        this.dtMonth = '12';
        break;
    }
    return  this.sHour + ":" + this.sMinute + " " + this.sAMPM;
  }

  padValue(value) {
    return (value < 10) ? "0" + value : value;
  }
  // This function is to convert the timezoneoffset to Standard format
  convert(value) {

    var hours = parseInt(value);
    value -= parseInt(value);
    value *= 60;
    var mins = value;
    value -= parseInt(value);
    value *= 60;
    var secs = parseInt(value);
    var display_hours = hours.toString();
    // handle GMT case (00:00)
    if (hours == 0) {
      display_hours = "00";
    } else if (hours > 0) {
      // add a plus sign and perhaps an extra 0
      display_hours = ((hours < 10) ? "+0" + hours : "+" + hours).toString();
    } else {
      // add an extra 0 if needed
      display_hours = ((hours > -10) ? "-0" + Math.abs(hours) : hours).toString();
    }
    mins = (mins < 10) ? "0" + mins : mins;
    return display_hours + ":" + mins;
  }
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  timeOffSet: any; hourOffSet: any; minuteOffSet: any;
  minutesOffSet: any; preValOffSet: any; hoursOffSet: any; final: any;
  convertOffset(gmt_offset) {
    this.timeOffSet = gmt_offset.toString().split(":");
    this.hourOffSet = parseInt(this.timeOffSet[0]);
    this.minuteOffSet = parseInt(this.timeOffSet[1]);
    this.minutesOffSet = this.minuteOffSet;
    this.preValOffSet = this.hourOffSet < 0 ? "-" : "+";
    this.hoursOffSet = Math.abs(this.hourOffSet) < 10 ? "0" + Math.abs(this.hourOffSet) : Math.abs(this.hourOffSet);
    //hour = negative ? "-" + hour : "+" + hour;
    this.final = parseInt(this.hoursOffSet);
    let data = this.final * 60;

    return this.preValOffSet + parseInt(data + this.minutesOffSet);
  }
  GetLocalTimeFromUTC(date) {

    var dateStringWithTime = moment(date).format('DD-MM-YYYY HH:mm:ss');
    var dateArr = dateStringWithTime.split('-');
    var val = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
    var dt_ = new Date(val);
    if (localStorage["LocalTime"] == null) {
      this.calculate_time_zone();
    }

    var TimeZoneHours = this.convertOffset(localStorage["LocalTime"]);
    let MILLISECS_PER_HOUR = TimeZoneHours /* min/hour */ * 60 /* sec/min */ * 1000 /* ms/s */;
    var newDate_ = new Date(+dt_ + MILLISECS_PER_HOUR);
    return this.formatDate(newDate_);
  }

}

