import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CommonService, UserDetails } from './views/shared/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'PRJ';
  showHeader = false;
  showSidebar = false;
  islogin = false;
  showFooter = false;
  LoginUser: UserDetails = new UserDetails();
  public baseUri = `${environment.WebApiBaseUrl}`;
  constructor(private router: Router
    //, public signalRService: SignalRService
    , private activatedRoute: ActivatedRoute, private http: HttpClient
    , private commonService: CommonService, private titleService: Title) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        this.titleService.setTitle('EZ Truck Permit | ' + data);
      }
    });
  }

  ngOnInit() {
     if ((environment.PublicApiBaseUrl != "" && environment.PublicApiBaseUrl != null) && environment.isLive === "yes") {
      if (location.protocol === 'http:') {
       //window.location.href = location.href.replace('http', 'https');
      }
    }
    if (sessionStorage.getItem("companyUrl") != null && sessionStorage.getItem("companyUrl") != "") {
      sessionStorage.removeItem("companyUrl");
    }

    if (localStorage.getItem("userId") != null && localStorage.getItem("userId") != "" && localStorage.getItem("userId") != "undefined") {
      document.getElementById('main_full').setAttribute("class", "main");
      if (location.pathname == '/myprofile') {
        this.commonService.getCurrentUserDetailWithModules(localStorage.getItem('userId'),'', '2');
      }
      else {
        this.commonService.getCurrentUserDetailWithModules(localStorage.getItem('userId'),'', '');
      }
    }
    else {
      document.getElementById('main_full').setAttribute("class", "main login_main");
    }

    

    var checkres = localStorage.getItem("usersucess");

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });

  }

}
