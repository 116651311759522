import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http';
//import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUri = `${environment.WebApiBaseUrl}`;
  Browser: any;
  BrowserVersion: any;
  OS: string
  OSVersion: string;
  deviceInfo = null;
  constructor(private http: HttpClient
    //, private deviceService: DeviceDetectorService
  ) { }

  epicFunction() {
    //this.deviceInfo = this.deviceService.getDeviceInfo();
    this.Browser = this.deviceInfo.browser;
    this.BrowserVersion = this.deviceInfo.browser_version;
    this.OS = "";
    this.OSVersion = this.deviceInfo.os_version;

  }
  login(loginModel: Login) {
    return this.http.post(this.baseUri + `Account/Login`, loginModel);
  }
  Guestlogin(loginModel: GuestLogin) {

    return this.http.post(this.baseUri + `Account/GuestLogin`, loginModel);
  }
  GuestloginNextProcess(loginModel: GuestLogin) {

    return this.http.post(this.baseUri + `Account/GuestloginNextProcess`, loginModel);
  }

  GmailAuthenticationForEmployee(loginModel: SocialloginModel) {

    return this.http.post(this.baseUri + `Account/GmailAuthenticationForEmployee`, loginModel);
  }
  demoTest() {
    return this.http.get(this.baseUri + `Support`);
  }
  ForgotPsw(forgotPswModel: ForgotPassword) {
    return this.http.post(this.baseUri + `Account/ForgotPassword`, forgotPswModel);
  }
  GetCred() {
    return this.http.get(this.baseUri + `Account/GetRememberMeCredentials`);
  }
  GetDBUtcdate() {
    return this.http.get(this.baseUri + `Common/GetCurrentUTCDateFromDB`)
  }
  getCurrentUserDetail() {
    return this.http.get(this.baseUri + `UserProfile/GetUserDetail`);
  }

  confirmEmail(emailConfirmModel: EmailConfirmModel) {
    return this.http.post(this.baseUri + `Account/Confirm`, emailConfirmModel);
  }
  refreshtoken(username: string) {
    this.epicFunction();
    return this.http.get(this.baseUri + `Account/RefreshToken?username=${username}&browser=${this.Browser}&browserVersion=${this.BrowserVersion}&os=${this.OS}&OSVersion=${this.OSVersion}`);
  }
  setPassword(setPassword: SetPassword) {
    
    return this.http.post(this.baseUri + `Account/SetPassword`, setPassword);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post(this.baseUri + `Account/ResetPassword`, resetPassword);
  }

  getUserProfile() {
    return this.http.get(this.baseUri + `UserProfile/GetUserDetail`);
  }
  getEmpName(companyusername: string, optionalparm: string) {
    return this.http.get(this.baseUri + `Account/GetEmpName/${companyusername}/${optionalparm}`);
  }

  updateUserProfile(userProfileModel: UserProfile) {
    return this.http.post(this.baseUri + `UserProfile/updateUserProfile`, userProfileModel);
  }

  GetTestSubmitedOrNot(employeeid: any, testId: any, testRequestId: any) {
    return this.http.get(this.baseUri + `EmployeeTest/GetTestSubmitedOrNot/${employeeid}/${testId}/${testRequestId}`);
  }

  getWaitingVideoLink(userId: string, companyId: string, testId: string) {
    return this.http.get(this.baseUri + `EmployeeTest/GetWaitingVideoLink/${userId}/${companyId}/${testId}`)
  }
  SaveRecoardingFile(model: SaveRecoardingFileModel) {
    model.userId = localStorage.getItem("switcheduserId");
    model.companyId = localStorage.getItem("companyId");
    return this.http.post(this.baseUri + `EmployeeTest/SaveRecoardingFile`, model);
  }

  RequestTestForObserveNow(testid: string, testRequestedId: string, companyId: string, userid: string) {
    return this.http.get(this.baseUri + `EmployeeTest/RequestTestForObserveNow/${testid}/${testRequestedId}/${companyId}/${userid}`);
  }


  Saveemployeecalldetail(model: SaveUserCallModel) {
    return this.http.post(this.baseUri + `EmployeeTest/SaveEmployeeCallDetails`, model);
  }
   
}


export class SaveUserCallModel {
  testId: string;
  ObserverId: string;
  employeeId: string;
  Teststarttime: string;
  Testendtime: string;

  constructor() {
    this.testId = '';
    this.ObserverId = '';
    this.employeeId = '';
    this.Teststarttime = '';
    this.Testendtime = '';

  }
}

export class SaveRecoardingFileModel {
  base64File: any;
  observerId: any;
  employeeId: any;
  testId: any;
  companyId: any;
  userId: any;
  constructor() {
    this.base64File = '';
    this.observerId = '';
    this.employeeId = '';
    this.testId = '';
    this.companyId = '';
    this.userId = '';
  }
}
export class Login {
  userName: string;
  password: string;
  rememberMe: boolean;
  browser: string;
  os: string;
  iPAddress: string;
  
  constructor() {
    this.userName = '';
    this.password = '';
    this.rememberMe = false;
    this.browser = '';
    this.os = '';
    this.iPAddress = '';
   
  }
}
export class SocialloginModel {
  displayName: string;
  email: string;
  emailVerified: string;
  expiresIn: string;
  federatedId: string;
  firstName: string;
  fullName: string;
  idToken: string;
  kind: string;
  lastName: string;
  localId: string;
  oauthIdToken: string;
  photoUrl: string;
  providerId: string;
  rawUserInfo: string;
  refreshToken: string;
  companyUrl: string;
}
export class Socialusers {
  provider: string;
  id: string;
  email: string;
  name: string;
  image: string;
  token?: string;
  idToken?: string;
}

export class GuestLogin {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  email: string;
  phone: string;
  otp: string;
  secureOTP: string;
  browser: string;
  os: string;
  iPAddress: string;
  companyUrl: string;

  constructor() {
    this.id = "";
    this.firstName = "";
    this.lastName = "";
    this.dob = "";
    this.gender = "";
    this.email = "";
    this.phone = "";
    this.otp = "";
    this.secureOTP = "";
    this.browser = "";
    this.os = "";
    this.iPAddress = "";
    this.companyUrl = "";
  }
}
export class SetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  token: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
    this.token = "";
  }
}
export class ResetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  resetToken: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
  }
}
export class ForgotPassword {
  username: string;

  constructor() {
    this.username = '';
  }
}

export class UserProfile {
  FirstName: string;
  LastName: string;
  UserType: string;
  Email: string;
  PhoneNumber: string;
  Address: string;
  Position: string;
  employeeType: string;
  isActive: boolean;
  notes: string;
  profilePic: string;
  Id: string;
  constructor() {
    this.FirstName = "";
    this.LastName = "";
    this.UserType = "";
    this.Email = "";
    this.PhoneNumber = "";
    this.Address = "";
    this.Position = "";
    this.employeeType = "";
    this.isActive = false;
    this.notes = "";
    this.Id = "";
  }
}

export class EmailConfirmModel {
  userId: any;
  token: any;
  constructor() {
    this.userId = null;
    this.token = "";
  }
}



