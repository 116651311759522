import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ModalModule } from 'ngx-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { SearchAsTypeComponent } from './search-as-type/search-as-type.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TruncateWord, UtcDateTimeToLocalPipe, UtctolocalPipe, UtctolocalTimePipe } from '../../pipes/truncate.pipe';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NotificationdetailComponent } from './notificationdetail/notificationdetail.component';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { LoaderBlackComponent } from 'src/app/components/loader/loader-black.component';
import { LoaderWhiteComponent } from 'src/app/components/loader/loader-white.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CommonTokenExpireComponent } from './confirmation-tokenexpire/common-token-expire.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { SafeHtmlPipe } from 'src/app/pipes/pipe.safeHtml';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { TwoDigitDecimaNumberDirectiveDirective } from "./two-digit-decima-number-directive.directive";
import { ChartModule } from 'primeng/chart';
import { OnlynumberDirective } from './onlyNumber';
import { QRCodeModule } from 'angular2-qrcode';
import { SmallconfirmationDialogComponent } from './smallconfirmation-dialog/smallconfirmation-dialog.component';
import { SmallConfirmationDialogService } from './smallconfirmation-dialog/smallconfirmation-dialog.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import { PopupComponent } from 'src/app/layout/popup/popup.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin, timeGridPlugin
]);

@NgModule({
  declarations: [
    UnauthorizedComponent
    , ConfirmationDialogComponent
    , CommonTokenExpireComponent, 
    TruncateWord, UtcDateTimeToLocalPipe, UtctolocalTimePipe,
    UtctolocalPipe,
    SafeHtmlPipe,
    SearchAsTypeComponent,
    NotificationdetailComponent,
    LoaderComponent,
    LoaderBlackComponent,
    LoaderWhiteComponent,
    FooterComponent,
    TwoDigitDecimaNumberDirectiveDirective,
    OnlynumberDirective
    , SmallconfirmationDialogComponent, PopupComponent
    
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    ModalModule.forRoot(),
    NgxPasswordToggleModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    FormsModule ,
    TooltipModule,
    InputMaskModule,
    NgSelectModule,
    CalendarModule,
    FullCalendarModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    CKEditorModule,
    PerfectScrollbarModule, QRCodeModule, ZXingScannerModule,
    DeviceDetectorModule.forRoot(),
    ChartModule

  ], providers: [
    ConfirmationDialogService,
    DatePipe,
    UtctolocalTimePipe, UtcDateTimeToLocalPipe, CurrencyPipe,
    SmallConfirmationDialogService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule, 
    NgSelectModule,
    NgxDatatableModule,
    CalendarModule,
    FullCalendarModule,
    ChartModule,
    AutoCompleteModule,
    SearchAsTypeComponent,
    TruncateWord, UtcDateTimeToLocalPipe,
    UtctolocalPipe, UtctolocalTimePipe,
    SafeHtmlPipe,
    TooltipModule,
    InputMaskModule,
    PopupComponent,
    NotificationdetailComponent,
    LoaderComponent,
    LoaderBlackComponent,
    LoaderWhiteComponent,
    PerfectScrollbarModule, FooterComponent,
    ProgressSpinnerModule,
    NgxPasswordToggleModule,
    TwoDigitDecimaNumberDirectiveDirective,
    OnlynumberDirective ,
    QRCodeModule, ZXingScannerModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    SmallconfirmationDialogComponent,
    CommonTokenExpireComponent
  ]
})
export class SharedModule { }
