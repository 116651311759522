import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, UserDetails, ChangePasswordModel, ModuleList } from 'src/app/views/shared/common.service';
import { ToastrService } from 'ngx-toastr';
//import { DOCUMENT } from '@angular/platform-browser';
import { Observable, Subject, of, concat, Subscription } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError, isEmpty, debounceTime, filter, map } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ConfirmationDialogService } from '../../views/shared/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  headerData: any = ''; imageLink = ''; VaccinationStatus = 'No';
  @ViewChild('ddlSearch', { static: false }) public ngSelectComponent: NgSelectComponent;
  modulePermission: ModuleList;
  userType: any;
  userDetail: any;
  public users$;
  public selectedUser;
  teamID: any='';
  teamMemberID: any = '';
  filteredData: any[];
  selectedData: any;
  length: any = 0;
  tablename: string;
  comptype: any;
  loadSave: boolean = false;
  loading = false;
  recordFilter: any;
  loginAsCompany: any;
  dashboardCounts: number = 0;
  IsEmployeeProfile: boolean = false;
  EmployeeTextas: any = '';
  observerSettingName: any;
  public typeahead = new EventEmitter<string>();
  progressSubscription: Subscription;
  progressValue: number;
  constructor(private routerService: Router, private toaster: ToastrService,
    private route: Router,
    private dialogService: ConfirmationDialogService,
    private commonService: CommonService, private activeRoute: ActivatedRoute
   
   // , @Inject(DOCUMENT) private document: any
  ) {

    this.progressSubscription = this.commonService.progress$.subscribe(
      progress => this.progressValue = progress
    );
  }

  username: any = '';
  elem;
  ngOnInit() {
    let userType = JSON.parse(localStorage.getItem('userinfo')).userType;
    this.IsEmployeeProfile = userType == "usertype08" ? true : false;
    this.commonService.getLoggedInName.subscribe((user: any) => {
      setTimeout(() => {
        this.modulePermission = this.commonService.getPermission(1021);
      }, 2000);
      this.headerSetup(user);
     
     
    });
    this.commonService.GetProgressData(localStorage.getItem("userId")).subscribe((result: any) => {
      if (result) {
        this.progressValue = result;
      }
    });
    this.elem = document.documentElement;
    this.GetDashboardCounts();
    this.EmployeeTextas = localStorage.getItem("SettingEmployee");
    this.observerSettingName = localStorage.getItem("SettingObserver");
  }

  ngOnDestroy(): void {
    this.progressSubscription.unsubscribe();
  }

  GetDashboardCounts() {
    //this.loadtop = true;
    this.commonService.GetDashboardCounts(localStorage.getItem("userId"), localStorage.getItem("companyId")).subscribe((result: any) => {
      if (result) {
        this.dashboardCounts = result;
        //this.loadtop = false;
      }
    },
      error => {
        //this.loadtop = false;
      })
  }
  headerSetup(user: any) {
    this.loginAsCompany = localStorage.getItem("isSwitched");
    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      this.userType = userdetail.userType;
      this.userDetail = userdetail;
      this.EmployeeTextas = localStorage.getItem("SettingEmployee");
       if (userdetail.id == null) {
        this.username = user.firstName + " " + user.lastName
        
      }
      else {
        this.username = userdetail.firstName + " " + userdetail.lastName
        this.getHeaderData(user.id);
      }
    });
  }

  getHeaderData(id: any) {
    this.commonService.getHeaderData(localStorage.getItem("userId"),'00Test').subscribe((res: any) => {
      this.headerData = res;
      this.EmployeeTextas = localStorage.getItem("SettingEmployee");
      this.imageLink = res.profilePic;
      this.VaccinationStatus = res.vaccinationStatus;
     
    },
      (error: any) => {
      });
  }

  logout() {
    this.commonService.logout();
  }
  sliderMenu() {
    let data = localStorage.getItem("sliderMenu");
    if (data == "1") {
      var id = document.getElementById("sidebar");
      if (id != null) {
        
        document.getElementById('sidebar').setAttribute("class", "sidebar js-sidebar collapsed");
        if (localStorage.getItem("userId") != null) {
          document.getElementById('main_full').setAttribute("class", "main full-section-cc")
        }
        else {
          document.getElementById('main_full').setAttribute("class", "login_main full-section-cc")
        }
      }
      localStorage.setItem('sliderMenu', JSON.stringify(2));
      this.commonService.SideMenuStatus(true);
    }
    else {
      localStorage.setItem('sliderMenu', JSON.stringify(1));
      document.getElementById('sidebar').removeAttribute("class");
      //sidebar js-sidebar collapsed
      document.getElementById('sidebar').setAttribute("class", "sidebar js-sidebar");
      if (localStorage.getItem("userId") != null) {
        document.getElementById('main_full').setAttribute("class", "main");
      }
      else {
        document.getElementById('main_full').setAttribute("class", "login_main")
      }
      this.commonService.SideMenuStatus(false);
    }
    
  }

  ClickTodisplayFullScreen() {
    let data = localStorage.getItem("fullScreenManu");
    if (data == "1" || data==null) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
      localStorage.setItem('fullScreenManu', JSON.stringify(2));
    }
    else {
      //localStorage.setItem('fullScreenManu', JSON.stringify(1));
      //if (document.exitFullscreen) {
      //  this.document.exitFullscreen();
      //} else if (this.document.mozCancelFullScreen) {
      //  /* Firefox */
      //  this.document.mozCancelFullScreen();
      //} else if (this.document.webkitExitFullscreen) {
      //  /* Chrome, Safari and Opera */
      //  this.document.webkitExitFullscreen();
      //} else if (this.document.msExitFullscreen) {
      //  /* IE/Edge */
      //  this.document.msExitFullscreen();
      //}
    }
  }

  onScrollToEnd() {
    if (this.selectedData == undefined) {
      this.selectedData = '';
    }
    this.length = this.filteredData.length;
    this.commonService.getGlobalSeacrchData(this.selectedData, this.length, this.recordFilter, this.teamID, this.teamMemberID).subscribe((res: any) => {

      
      this.filteredData = res;
      (this.filteredData as any[]) = (this.filteredData as any[]).concat(this.filteredData);
    })
  }

  btnSearch(term: any): Observable<any> {
    this.selectedData = term;
    this.loadSave = true;
    return this.commonService.getGlobalSeacrchData(this.selectedData, this.length, this.recordFilter, this.teamID, this.teamMemberID);
  }

  bindGlobalSearch() {
    this.users$ = this.typeahead.pipe(
      filter((term) => term.length >= 3), // HERE I FILTERED THE TYPEAHEAD 
      distinctUntilChanged(),
      debounceTime(200),
      tap(() => this.loadSave = true),
      switchMap(
        (term) => this.btnSearch(term).pipe(
          catchError(() => of({ items: [] })),
          map(rsp => rsp),
          tap(() => this.loadSave = false),
        )
      )
    );
  }

  Clearddl() {
    
    this.users$ = [];
    this.selectedData = null;
    this.bindGlobalSearch();
  }

  closedropdown() {
    
    this.users$ = [];
    this.selectedData = null;
    this.bindGlobalSearch();

  }
  redirectToPage(link: any) {
    this.ngSelectComponent.close();

    this.filteredData = null;
    this.route.navigateByUrl(link);
  }

  SwichedAsSuperAdmin() {
    const message = `Are you sure you want to login as Super Admin?`;
    this.dialogService.confirm('Login as Super Admin', message).subscribe(confirmed => {
      if (confirmed) {
        this.loading = true;
        let userid = localStorage.getItem("SwichedAsSuperAdmin");
        localStorage.setItem("userId", userid );
        localStorage.removeItem('switcheduserId');
        localStorage.setItem("switcheduserId", userid);
        this.commonService.getCurrentUserDetail(userid);
        setTimeout(() => {
          this.commonService.getLoggedInName.subscribe((userdetail: any) => {
            if (localStorage.getItem("userId") != null) {
              document.getElementById('main_full').setAttribute("class", "main");
            }
            this.commonService.getUserModulePermissions(localStorage.getItem("SwichedAsSuperAdmin"),'').subscribe((m: any) => {
              localStorage.removeItem('moduleList');
              localStorage.setItem('moduleList', JSON.stringify(m));
              localStorage.setItem("usersucess", "yes");
              localStorage.removeItem("loginSuperadminAsComp");
              localStorage.setItem("loginSuperadminAsComp", "no");
              localStorage.removeItem("actualUserId");
              localStorage.removeItem("SwichedAsSuperAdmin");
              localStorage.removeItem('isSwitched');
              localStorage.setItem('isSwitched', 'no');
              
              document.getElementById('main_full').setAttribute("class", "main")
              //this.route.navigateByUrl('/account');
              window.location.href = '/#/dashboard';
              window.location.reload();            
            });
          });
        }, 1000);

        setTimeout(() => { this.loading = false; }, 1000);
      }
    });
  }

  SwichedAsObserver(userid, actions) {
    
    let message;
    let dynamictext;
    if (actions == 'AsAObserver') {
      
      dynamictext = localStorage.getItem("SettingObserver");
      message = `Are you sure you want to login as "${localStorage.getItem('SettingObserver')}"?`;
    }
    else {
      dynamictext = localStorage.getItem("SettingEmployee");
      message = `Are you sure you want to login as "${localStorage.getItem('SettingEmployee')}"?`;
    }
     
    this.dialogService.confirm('Login as ' + dynamictext, message).subscribe(confirmed => {
      if (confirmed) {
        this.loading = true;
        //let userid = localStorage.getItem("SwichedAsSuperAdmin");
        localStorage.setItem("userId", userid);
        localStorage.removeItem('switcheduserId');
        localStorage.setItem("switcheduserId", userid);
        localStorage.removeItem('actionType');
        localStorage.setItem("actionType", actions);
        this.commonService.getCurrentUserDetail(userid, actions);
        setTimeout(() => {
          this.commonService.getLoggedInName.subscribe((userdetail: any) => {
            if (localStorage.getItem("userId") != null) {
              document.getElementById('main_full').setAttribute("class", "main");
            }
            this.commonService.getUserModulePermissions(userid, actions).subscribe((m: any) => {
              localStorage.removeItem('moduleList');
              localStorage.setItem('moduleList', JSON.stringify(m));
              sessionStorage.removeItem("companyUrl");
              localStorage.setItem("usersucess", "yes");
              document.getElementById('main_full').setAttribute("class", "main")
              //this.route.navigateByUrl('/account');
              window.location.href = '/#/dashboard';
              window.location.reload();
            });
          });
        }, 1000);

        setTimeout(() => { this.loading = false; }, 1000);
      }
    });
  }
}
