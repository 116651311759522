// popup.component.ts
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupService } from './popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent {
  private closePopupSubscription: Subscription;
  isPopupVisible: boolean = true;
  isSign: boolean= false;
  constructor(private router: Router, private popupService: PopupService) {
    
    this.closePopupSubscription = this.popupService.isPopupVisible.subscribe((isVisible) => {
      this.isPopupVisible = isVisible;
    });}
  ngOnInit() {
    //this.loading = true;
    if (localStorage.getItem('isMandatoryFieldDone') != 'true') {
      this.isSign = false;
    }
    else if (localStorage.getItem('signatureFileName') == '' || localStorage.getItem('signatureFileName') == null || localStorage.getItem('signatureFileName') == 'null' || localStorage.getItem('signatureFileName') == 'undefined') {
      this.isSign = true;
    }
  }
  redirectToOtherPage() {
    this.popupService.closePopup();
    // Close the popup before navigating to the other page
    // (You can implement this behavior based on your actual popup implementation)
    this.router.navigate(['/myprofile']);
  }
  redirectToSign() {
    this.popupService.closePopup();
    // Close the popup before navigating to the other page
    // (You can implement this behavior based on your actual popup implementation)
    this.router.navigate(['/managedqf/signature']);
  }
  

  closePopup() {
    this.isPopupVisible = false;
  }
  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.closePopupSubscription.unsubscribe();
  }
}
