import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
//import { CommonTokenExpireService } from "../views/shared/confirmation-tokenexpire/common-token-expire.service";
import { CommonService } from "../views/shared/common.service";

@Injectable()
export class AuthIntercepter implements HttpInterceptor {
  constructor(private router: Router,
    //private modalService: CommonTokenExpireService,
    private commonservice: CommonService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (localStorage.getItem("access_token") != null) {
      const clonedReq = req.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,POST, PATCH,PUT,DELETE,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-Requested-With,Accept',
          'Cache-Control': 'no-cache',//, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0',
          'Authorization': 'token_value ' + localStorage.getItem("access_token")
        })

      });
      return next.handle(clonedReq).pipe(
        tap(
          succ => { },
          err => {
            if (err.status === 401) {
              localStorage.removeItem("access_token");
              localStorage.setItem("sadadss", "asdsadsa");
              if (this.router.url != "/" && this.router.url != "/account") {
                //this.modalService.open("Session Timeout", "Your session is about to expire.");
              }
              else { this.router.navigateByUrl("/account"); }
            }
          }
        )
      )
    }
    else {
     // this.modalService.close();
      const clonedReq = req.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,POST, PATCH,PUT,DELETE,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Cache-Control': 'no-cache',//, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0',
          'Authorization': 'token_value ' + localStorage.getItem("access_token")
        })
      });
      return next.handle(req.clone(clonedReq));
    }
  }
}
