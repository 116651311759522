// popup.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private isPopupVisibleSubject = new BehaviorSubject<boolean>(false);

  isPopupVisible = this.isPopupVisibleSubject.asObservable();

  openPopup() {
    this.isPopupVisibleSubject.next(true);
  }

  closePopup() {
    this.isPopupVisibleSubject.next(false);
  }
}
