import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, ModuleModel } from 'src/app/views/shared/common.service';
import { ToastrService } from 'ngx-toastr';
import { PopupComponent } from '../popup/popup.component';
import { PopupService } from '../popup/popup.service';
/*import { debug } from 'util';*/

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [],
  entryComponents: [PopupComponent]
})
export class SidebarComponent implements OnInit {
  loadSave = false;
  moduleList: ModuleModel[];
  userId: any;
  observerTextas: any = '';
  settingEmployee: any = '';
  isPopupOpen: boolean = false;
  userdetail: any;
  isMandatoryFieldDone: any = 'true';
  constructor(private commonService: CommonService, private toaster: ToastrService, private popupService: PopupService) { }

  ngOnInit() {
    debugger;
    this.observerTextas = localStorage.getItem("SettingObserver");
    this.settingEmployee = localStorage.getItem("SettingEmployee");
    this.commonService.getLoggedInName.subscribe((user: any) => {
      this.sidebarSetup(user);
    });
    this.isMandatoryFieldDone = localStorage.getItem("isMandatoryFieldDone");
  }
  SignOut() {
    this.commonService.logout();
  }

  handleClick() {
    debugger;
    this.isPopupOpen = true;
    this.popupService.openPopup();
     // this.toaster.error('Some of Driver info is missing, please fill the required data first!');
    
  }
  
  sidebarSetup(user: any) {
    this.loadSave = true;
    this.userId = user.id;
    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      if (userdetail.id != null) {
        this.userId = userdetail.id
        this.userdetail = userdetail;
       
      }
    });
    if (typeof this.userId !== "undefined" && typeof this.userId !== null) {
      this.commonService.getModuleList(localStorage.getItem('userId')).subscribe((response: any) => {
        if (response) {
          this.moduleList = response;
          this.loadSave = false;
        }
        else {
          this.moduleList = [];
        }
      }, error => {
        this.loadSave = false;
      });
    }
  }
}
